body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.adhereForm {
  padding-left: 15px;
  text-align: left;
}

.adhereInput {
  padding-left: 15px;
}

.version {
  padding-right: 15px;
  text-align: right;
}

.dialog {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.dialog-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 30px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}