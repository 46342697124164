.App {
  text-align: center;
}

.primary {
  background-color: #04AA6D;
  width: 100%;
  max-width: 320px;
}

.secondary {
  background-color: #2f89b6;
}

.button {
  border: none;
  color: white;
  margin: 10px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button:hover {
  opacity: .8;
}

.button:disabled {
  background-color: #95aca3;
  opacity: .8;
}

.button:focus {
  outline-style:solid;
  box-shadow: 0 0 0 4px  #04AA6D;
}

.hint {
  color: #95aca3;
  font-size: smaller;
}

input:invalid {
  color: red;
}

.error {
  color: red;
}

.loader-green {
  width: 48px;
  height: 48px;
  border: 5px solid #04AA6D;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-blue {
  width: 48px;
  height: 48px;
  border: 5px solid #2f89b6;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 